<template>
  <FormContainer @submitForm="submit">
    <FeedbackAlert v-if="feedback === false">Errore nel salvataggio dei dati! Riprovare</FeedbackAlert>

    <div v-if="!edit" class="row">
      <FormInputTextWidget
        class="col-md-2"
        label="Anno"
        not-required
        type="number"
        :min="1900"
        :max="currentYear"
        v-model="internalValue.year"
        :errorMsg="errorHash.year"
        @input="removeFieldError('year')"
      />

      <SelectPackageWidget
        class="col-10"
        label="Confezione"
        v-model="internalValue.package"
        :errorMsg="errorHash.package"
        @input="removeFieldError('package')"
      />
    </div>
    <div v-else>
      <div class="col-md-2">
        <strong>Anno</strong><br>
        <p v-if="!internalValue.year">Qualsiasi anno</p>
        <p v-else>{{ internalValue.year }}</p>
      </div>

      <div class="col-md-10">
        <strong>Confezione</strong><br>
        <p>{{ internalValue.packageName }} {{ internalValue.packageMaterial }} ({{ internalValue.packageVolume / 1000 }} L)</p>
      </div>
    </div>

    <div class="row">
      <FormInputTextWidget
        class="col-md-6"
        label="Peso"
        type="number"
        :min="0"
        step="1"
        v-model="internalValue.weight"
        :errorMsg="errorHash.weight"
        @input="removeFieldError('weight')"
      />

      <FormInputTextWidget
        class="col-md-6"
        label="Gradazione"
        type="number"
        :min="0"
        step=".1"
        v-model="internalValue.alcoholContent"
        :errorMsg="errorHash.alcoholContent"
        @input="removeFieldError('alcoholContent')"
      />

      <FormInputTextWidget
        class="col-md-6"
        label="Quantità"
        type="number"
        :min="0"
        v-model="internalValue.quantity"
        :errorMsg="errorHash.quantity"
        @input="removeFieldError('quantity')"
      />

      <FormInputCurrencyWidget
        class="col-md-6"
        label="Prezzo di acquisto"
        type="number"
        :min="0"
        v-model="internalValue.purchasePrice"
        :errorMsg="errorHash.purchasePrice"
        @input="removeFieldError('purchasePrice')"
      />
    </div>
    <div class="row">
      <div class="col-md-7 d-flex flex-column">
        <FormLabel label-is-fake>Margine operativo</FormLabel>
        <div class="row">
          <FormInputPercentageWidget class="col-4" hide-label v-model="freeMargin" />
          <FormInputCurrencyWidget class="col" hide-label :value="Math.round(internalValue.purchasePrice * (1 + (freeMargin/100)))" disabled />
          <div class="col-auto">
            <button type="button" class="btn btn-secondary" @click="internalValue.defaultSellingPrice = Math.round(internalValue.purchasePrice * (1 + (freeMargin/100)))">Applica</button>
          </div>
        </div>
        <div v-for="margin in margins" class="row mt-3" :key="'margin-' + margin">
          <FormInputPercentageWidget class="col-4" hide-label :value="margin" disabled />
          <FormInputCurrencyWidget class="col" hide-label :value="Math.round(internalValue.purchasePrice * (1 + (margin/100)))" disabled />
          <div class="col-auto">
            <button type="button" class="btn btn-secondary" @click="internalValue.defaultSellingPrice = Math.round(internalValue.purchasePrice * (1 + (margin/100)))">Applica</button>
          </div>
        </div>
      </div>

      <FormInputCurrencyWidget
        class="col-md-5"
        label="Prezzo di vendita standard"
        type="number"
        :min="0"
        v-model="internalValue.defaultSellingPrice"
        :errorMsg="errorHash.defaultSellingPrice"
        @input="removeFieldError('defaultSellingPrice')"
      />
    </div>
  </FormContainer>
</template>

<script>

import validateMixin from '@/libs/Form/mixins/validate.js';

import FormLabel from '@/libs/Form/components/FormLabel.vue';
import FormInputTextWidget from '@/libs/Form/components/Inputs/Widgets/FormInputTextWidget.vue';
import FormInputCurrencyWidget from '@/libs/Form/components/Inputs/Widgets/FormInputCurrencyWidget.vue';
import FormInputPercentageWidget from '@/libs/Form/components/Inputs/Widgets/FormInputPercentageWidget.vue';
import SelectPackageWidget from './Widgets/SelectPackageWidget.vue';
import FeedbackAlert from '@/libs/Feedback/components/Alert.vue';
import FormContainer from '@/views/components/Form/FormContainer.vue';

export default {
  mixins: [validateMixin],
  components: {
    FormContainer,
    FormLabel,
    FormInputTextWidget,
    FormInputCurrencyWidget,
    FormInputPercentageWidget,
    SelectPackageWidget,
    FeedbackAlert,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        year: null,
        package: null,
        weight: null,
        alcoholContent: null,
        quantity: null,
        defaultSellingPrice: null,
        purchasePrice: null,
      }),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: Boolean,
      default: null,
    },
  },
  data () {
    return {
      currentYear: (new Date()).getFullYear(),
      iValue: { ...this.value },
      margins: [25, 30, 35, 40, 45, 50, 55],
      freeMargin: null,
    };
  },
  computed: {
    internalValue: {
      get () {
        return this.iValue;
      },
      set (value) {
        this.iValue = value;
      },
    },
  },
  watch: {
    value: {
      handler (newValue) {
        this.iValue = { ...newValue };
      },
      immediate: true,
    },
  },
  methods: {
    removeFieldError (field) {
      this.removeFeedback();
      this.removeError(field);
    },
    removeFeedback () {
      this.$emit('update:feedback', null);
    },
    isValid () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.iValue.package) {
        this.addError('Seleziona la confezione', 'package');
      }

      if (!this.iValue.weight && 0 !== this.iValue.weight) {
        this.addError('Inserisci il peso', 'weight');
      }

      if (!this.iValue.alcoholContent && 0 !== this.iValue.alcoholContent) {
        this.addError('Inserisci la gradazione', 'alcoholContent');
      }

      if (!this.iValue.quantity && 0 !== this.iValue.quantity) {
        this.addError('Inserisci la quantità', 'quantity');
      }

      if (!this.iValue.purchasePrice && 0 !== this.iValue.purchasePrice) {
        this.addError('Specifica il prezzo di acquisto', 'purchasePrice');
      }

      if (!this.iValue.defaultSellingPrice && 0 !== this.iValue.defaultSellingPrice) {
        this.addError('Specifica il prezzo di vendita di default', 'defaultSellingPrice');
      }

      return !this.hasErrors;
    },
    submit () {
      if (!this.isValid()) {
        return;
      }

      const valueToReturn = { ...this.iValue };

      this.$emit('submitForm', valueToReturn);
    },
  },
};

</script>
