<template>
  <DialogModal ref="modal" class="product-modal" btnLabel="Salva" :title="edit ? value.wineName : 'Prodotto'" @close="$emit('close', $event)">
    <template #actions="{ close }">
      <div class="w-100 text-end">
        <button class="btn btn-primary" @click="closeModal(close)">Salva</button>
      </div>
    </template>

    <template #default>
      <WinePackageForm
        ref="form"
        :value="value"
        @submitForm="$emit('submitForm', $event)"
        :edit="edit"
        :feedback="feedback"
        @update:feedback="$emit('update:feedback', $event)"
      />
    </template>
  </DialogModal>
</template>

<script>

import DialogModal from '@/libs/Modals/components/DialogModal.vue';
import WinePackageForm from '@/views/components/Form/WinePackageForm.vue';

export default {
  components: { DialogModal, WinePackageForm },
  props: {
    value: {
      type: Object,
      required: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    closeModal () {
      this.$refs['form'].submit();
    },
  },
};

</script>

<style lang="scss">

.product-modal {
  .modal-dialog {
    max-width: 800px;
  }
}

</style>
