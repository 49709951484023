<template>
  <FormInputSelectWidget :notRequired="notRequired" :label="label" :hideLabel="hideLabel" :hasError="hasError" :errorMsg="errorMsg" :options="packageOptions" :value="value" @input="$emit('input', $event)" />
</template>

<script>

import formInputWidgetMixin from '@/libs/Form/components/Inputs/mixins/formInputWidget.js';

export default {
  mixins: [formInputWidgetMixin],
  components: {
    FormInputSelectWidget: () => import('@/libs/Form/components/Inputs/Widgets/FormInputSelectWidget.vue'),
  },
  props: {
    packages: {
      type: Array,
      default: null,
    },
  },
  data () {
    return {
      allPackages: null,
    };
  },
  computed: {
    completePackages () {
      return this.packages || this.allPackages || [];
    },
    packageOptions () {
      return this.completePackages.map(p => {
        p.value = p.id;
        p.label = `${p.name} ${p.material} (${p.volume / 1000} L)`;

        return p;
      });
    },
  },
  mounted () {
    if (null === this.packages) {
      this.$api.listPackages()
        .then(res => {
          this.allPackages = res.data.packages;
        })
        .catch(this.$log.error)
      ;
    }
  },
};

</script>
